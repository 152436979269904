/* global jsVars */
const containerVideo = document.querySelector('.tlw__prevedit .video');
const containerContent = document.querySelector('.tlw__prevedit .content');
const video = document.getElementById('tlw-prevedit');
const fullScreen = document.getElementById('full-screen-prevedit');

window.addEventListener('load', function () {
	if (document.body.contains(video)) {
		const imageFullScreen = fullScreen.querySelector('img');

		fullScreen.addEventListener('click', () => {
			containerVideo.classList.toggle('active');
			containerContent.classList.toggle('active');
			if (containerVideo.classList.contains('active')) {
				imageFullScreen.src = jsVars.theme_root + '/assets/img/icon-full-screen.svg';
			} else {
				imageFullScreen.src = jsVars.theme_root + '/assets/img/icon-full-screen-2.svg';
			}
		});
	}
});
